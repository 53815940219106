import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "./PrimaryButton";
import { IoIosArrowBack } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import thankyou from "../assets/images/thankyou.png";
import { useSelector } from "react-redux";
import menu_x from "../assets/images/menu_x.png";
import Cookies from "js-cookie";

const CRYPTO_JS = require("crypto-js");
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

function computeHmacSha256(data, key) {
  const keys = Object.keys(data).sort();
  const sortedObject = {};
  keys.forEach((key) => {
    sortedObject[key] = data[key];
  });
  let dataString = "";
  for (let key in sortedObject) {
    const value = sortedObject[key];
    if (Array.isArray(value) || typeof value === "object") {
      dataString += CRYPTO_JS.SHA256(JSON.stringify(value)).toString(
        CRYPTO_JS.enc.Hex
      );
    } else {
      dataString += value;
    }
  }
  const hash = CRYPTO_JS.HmacSHA256(dataString, key);
  return CRYPTO_JS.enc.Hex.stringify(hash);
}

const GetInTouchModal = ({ showModal, setShowModal }) => {
  const { servicesData, occasionData, countryCodesData, topCitiesData } =
    useSelector((state) => state.home);

  const [utmSource, setUtmSource] = useState("Site Default");
  const [utmMedium, setUtmMedium] = useState("Site Default");
  const [utmCampaign, setUtmCampaign] = useState("Site Default");
  const [utmContent, setUtmContent] = useState("Site Default");
  const [utmTerm, setUtmTerm] = useState("Site Default");
  const [gadid, setGadid] = useState("Site Default");

  const [check, setCheck] = useState(false);
  const [budget, setBudget] = useState([]);
  const [selectedServiceName, setSelectedServiceName] = useState("");

  const [suggestions, setSuggestions] = useState([]);

  const handleVenueChange = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, selectedLocations: value });

    if (value.length > 0) {
      const filteredSuggestions = topCitiesData.filter((city) =>
        city.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleVenueSuggestions = (suggestion) => {
    setFormData({ ...formData, selectedLocations: suggestion });
    setSuggestions([]);
  };

  const [formData, setFormData] = useState({
    fullName: "",
    phnNumber: "",
    eventDate: "",
    eventVenue: "",
    sendOnWhatsapp: true,
    selectedOcassions: [],
    selectedServices: [],
    selectedBudgetRanges: [],
    selectedGuestRange: "",
    selectedLocations: "",
    validateOTP: "",
    countryCode: "+91",
    lead_id: "",
    selectedBudgets: [],
  });
  const modalRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentServiceBudgetPage, setCurrentServiceBudgetPage] = useState(0);
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowModal(false);
      setCurrentPage(0);
      setCurrentServiceBudgetPage(0);
      setFormData({
        fullName: "",
        phnNumber: "",
        eventDate: "",
        eventVenue: "",
        sendOnWhatsapp: true,
        selectedOcassions: [],
        selectedServices: [],
        selectedBudgetRanges: [],
        selectedLocations: "",
        validateOTP: "",
        selectedGuestRange: "",
      });
    }
  };

  useEffect(() => {
    if (showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showModal]);

  useEffect(() => {
    // Retrieve cookies with defaults if undefined
    setUtmSource(Cookies.get("utm_source") || "Site Default");
    setUtmMedium(Cookies.get("utm_medium") || "Site Default");
    setUtmCampaign(Cookies.get("utm_campaign") || "Site Default");
    setUtmContent(Cookies.get("utm_content") || "Site Default");
    setUtmTerm(Cookies.get("utm_term") || "Site Default");
    setGadid(Cookies.get("gadid") || "Site Default");
  }, []);

  const handleBackClick = () => {
    if (currentPage === 4) {
      if (
        formData.selectedServices.length > 0 &&
        currentServiceBudgetPage > 0
      ) {
        const previousServiceIndex = currentServiceBudgetPage - 1;

        const result = servicesData.find(
          (item) =>
            item.service === formData.selectedServices[previousServiceIndex]
        );

        if (result) {
          const decodedStrings = result.budgets.map((str) => str);
          setSelectedServiceName(
            formData.selectedServices[previousServiceIndex]
          );
          setBudget(decodedStrings);
          setCurrentServiceBudgetPage(previousServiceIndex);

          // Update selected budget ranges
          const selectedBudget = formData.selectedBudgetRanges.find(
            (budget) => budget.range === formData.selectedBudgetRanges[0].range
          );
          if (selectedBudget) {
            setFormData((prevData) => ({
              ...prevData,
              selectedBudgetRanges: [selectedBudget],
            }));
          }
        } else {
          console.error("Service not found for the previous index");
        }
      } else {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
      }
    } else {
      const selectedBudget = formData.selectedBudgetRanges.find(
        (budget) => budget === formData.selectedBudgetRanges
      );
      if (selectedBudget) {
        setFormData((prevData) => ({
          ...prevData,
          selectedBudgetRanges: [selectedBudget],
        }));
      }
      
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
    }
  };

  const getSubTitle = () => {
    if (currentPage === 0) return "Speak to Our";
    if (currentPage === 1) return "";
    if (currentPage === 2) return "Select Your";
    if (currentPage === 3) return "Select Our";
    if (currentPage === 4) return "Select Your";
    if (currentPage === 5) return "Total Number of";
    if (currentPage === 6) return "Enter Your";
    if (currentPage === 7) return "";
  };

  const getTitle = () => {
    if (currentPage === 0) return "Wedding planners!";
    if (currentPage === 1) return "Enter OTP";
    if (currentPage === 2) return "Occasions";
    if (currentPage === 3) return "Services";
    if (currentPage === 4) {
      return `Budget Range for ${selectedServiceName}`;
    }
    if (currentPage === 5) return "Guests";
    if (currentPage === 6) return "Venue";
    if (currentPage === 7) return "Thank You";
  };

  const formDetails = {
    ocassions: occasionData.map((data) => data.title),
    services: servicesData.map((data) => data.service),

    guestRanges: ["100", "250", "500", "1000", "1500", "3000", "5000"],
    locations: [
      "Mumbai",
      "Goa",
      "Delhi",
      "Banglore",
      "Jaipur",
      "Pune",
      "Udaipur",
      "Jodhpur",
      "Chennai",
      "Calcutta",
      "Kochin",
      "Indore",
    ],
  };

  if (!showModal) return null;

  const updateGenerateLead = async (formData) => {
    const filteredOccasions = occasionData
      .filter((data) => formData.selectedOcassions.includes(data.title))
      .map((data) => parseInt(data.occasionID)); // Convert to integer

    const filteredServices = servicesData
      .filter((data) => formData.selectedServices.includes(data.service))
      .map((data) => parseInt(data.id));

    const finalServices = filteredServices.reduce((acc, service, index) => {
      acc[service] = [parseInt(formData.selectedBudgets[index].id)];
      return acc;
    }, {});

    // Create services object as expected by the API
    // const filteredServices = formData.selectedBudgetRanges.reduce(
    //   (acc, res) => {
    //     if (!acc[`${res.id}`]) {
    //       acc[`${res.id}`] = [];
    //     }
    //     acc[`${res.id}`].push(res.index);
    //     return acc;
    //   },
    //   {}
    // );

    try {
      const requestBody = {
        lead_id: formData.lead_id,
        email: formData.email || "", // Include email if it's required
        location: formData.selectedLocations,
        people: formData.selectedGuestRange,
        occassions: filteredOccasions,
        services: finalServices,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      const response = await fetch("https://spreeh.com/apps/lead", {
        method: "POST",
        headers: {
          "X-Hash": hashValue, // No Content-Type header when sending FormData
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.status === "ok") {
        toast.success("Lead Generated successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
        if (currentPage > 6) {
          setCurrentPage(0);
        }
      } else {
        toast.error("Lead Generation Failed. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submit:", error);
    }
  };

  const generateLead = async (formData) => {
    const isoDateString = formData.eventDate;
    const date = moment(isoDateString);
    const formattedDate = date.format("YYYY-MM-DD");
    try {
      const requestBody = {
        country_code:
          formData.countryCode == null ? "+91" : formData.countryCode,
        mobile: formData.phnNumber,
        name: formData.fullName,
        email: "",
        event: formattedDate,
        utm_source: utmSource,
        utm_medium: utmMedium,
        utm_campaign: utmCampaign,
        utm_content: utmContent,
        utm_term: utmTerm,
        gadid: gadid,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      const response = await fetch("https://spreeh.com/apps/lead", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Hash": hashValue,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();
      if (data.status === "ok") {
        setFormData({ ...formData, lead_id: data.lead_id });
        let currPage = currentPage;
        currPage++;
        setCurrentPage(currPage);
      } else {
        toast.error("Lead Generation Failed. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submit:", error);
    }
  };

  const validateOtpRequest = async (formData) => {
    try {
      const requestBody = {
        mobile: formData.phnNumber,
        utype: "customer",
        otp: formData.validateOTP,
        country_code:
          formData.countryCode == null ? "+91" : formData.countryCode,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      const response = await fetch("https://spreeh.com/apps/verifyotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Hash": hashValue,
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      if (data.status === "ok") {
        let currPage = currentPage;
        currPage++;
        setCurrentPage(currPage);
        toast.success("OTP Verified successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
        generateLead(formData);
      } else {
        toast.error("Failed to verify OTP. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error verifieng OTP:", error);
    }
  };

  const sendOtpRequest = async (formData) => {
    try {
      const requestBody = {
        mobile: formData.phnNumber,
        utype: "customer",
        country_code:
          formData.countryCode == null ? "+91" : formData.countryCode,
      };

      const hashValue = computeHmacSha256(requestBody, SECRET_KEY);

      const response = await fetch("https://spreeh.com/apps/sendotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Hash": hashValue,
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status === "ok") {
        let currPage = currentPage;
        currPage++;
        setCurrentPage(currPage);
        toast.success("OTP Sent successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Failed to generate OTP. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className="w-[93%] md:w-[35%] flex justify-center align-center"
        // ref={modalRef}
      >
        <div className="bg-white rounded shadow-lg w-full md:px-5 min-h-[580px] max-h-[580px] max-w-7xl sm:mx-0 overflow-y-auto mt-[-60px] md:mt-[0px]">
          {currentPage > 0 ? (
            <div className="flex justify-between">
              <div className="flex justify-start align-center text-base pt-6 pl-4">
                <IoIosArrowBack
                  className="text-medium-pink cursor-pointer"
                  onClick={handleBackClick}
                />
                <div className="mt-[-5px]" onClick={handleBackClick}>
                  {"Back"}
                </div>
              </div>
              <div
                className="flex justify-end align-center text-base pt-6 pr-6 md:pr-6"
                onClick={() => setShowModal(false)}
              >
                <img
                  alt="img"
                  className="w-[10px] h-[10px] object-cover"
                  src={menu_x}
                />
              </div>
            </div>
          ) : (
            <div
              className="flex justify-end align-center text-base pt-6 pr-6 md:pr-6"
              onClick={() => setShowModal(false)}
            >
              <img
                alt="img"
                className="w-[10px] h-[10px] object-cover"
                src={menu_x}
              />
            </div>
          )}

          <div className="flex flex-col items-center">
            <h3
              className="text-lg font-semibold text-center text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
              }}
            >
              {getSubTitle()}
            </h3>
            <h3
              className="text-2xl font-semibold text-center text-medium-pink"
              style={{
                fontFamily: "Tiro Devanagari Sanskrit, serif",
                fontWeight: 400,
                fontStyle: "italic",
              }}
            >
              {getTitle()}
            </h3>
          </div>
          <div className="px-8">
            {currentPage === 0 && (
              <h3 className="font-['Manrope'] text-black text-sm font-semibold text-center mt-2">
                Have all your questions answered and get a free competitive
                quote
              </h3>
            )}
            {currentPage === 7 && (
              <h3
                className="text-lg font-semibold text-center text-medium-pink pt-10"
                style={{
                  fontFamily: "Tiro Devanagari Sanskrit, serif",
                  fontWeight: 400,
                }}
              >
                for filling out all the details. A Spreeh representative will
                call you shortly.
              </h3>
            )}
          </div>
          {currentPage === 0 && (
            <div className="p-4">
              <div className="mb-4 md:pt-4">
                <label
                  htmlFor="fullName"
                  className="block text-black font-['Manrope'] font-semibold text-xs"
                >
                  Full Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  onChange={(e) => {
                    setFormData({ ...formData, fullName: e.target.value });
                  }}
                  value={formData.fullName}
                  className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 text-xs ${
                    formData.fullName !== "" ? "bg-white" : "bg-gray-100"
                  } border-gray-300`}
                  placeholder="Full Name"
                />
                {check && formData.fullName === "" && (
                  <p className="text-red-600 text-xs">Full Name Required</p>
                )}
                <label
                  htmlFor="Phone"
                  className="mt-2 block text-black font-['Manrope'] font-semibold text-xs pt-3"
                >
                  Phone Number <span style={{ color: "red" }}>*</span>
                </label>
                <div className="flex mt-1">
                  <select
                    id="countryCode"
                    name="countryCode"
                    onChange={(e) => {
                      setFormData({ ...formData, countryCode: e.target.value });
                    }}
                    value={formData.countryCode}
                    className="px-3 py-2 border rounded-l border-gray-300 bg-gray-100 focus:border-pink-500 text-xs"
                  >
                    {countryCodesData &&
                      Object.entries(countryCodesData).map(
                        ([code, country]) => (
                          <option key={code} value={code}>
                            {`${code} (${country})`}
                          </option>
                        )
                      )}
                  </select>
                  <input
                    id="Phone"
                    name="Phone"
                    type="tel"
                    onChange={(e) => {
                      setFormData({ ...formData, phnNumber: e.target.value });
                    }}
                    value={formData.phnNumber}
                    className={`w-full px-3 py-2 border rounded-r focus:border-pink-500 text-xs ${
                      formData.phnNumber !== "" ? "bg-white" : "bg-gray-100"
                    } border-gray-300`}
                    placeholder="Phone Number"
                  />
                </div>
                {check && formData.phnNumber === "" && (
                  <p className="text-red-600 text-xs">Phone Number Required</p>
                )}
                {check &&
                  formData.phnNumber.length > 1 &&
                  (formData.phnNumber.length < 10 ||
                    formData.phnNumber.length > 10) && (
                    <p className="text-red-600 text-xs">
                      Phone Number Must Be 10 Numbers
                    </p>
                  )}
                <label
                  htmlFor="Date"
                  className="mt-2 block text-black font-['Manrope'] font-semibold text-xs pt-3"
                >
                  Event Date <span style={{ color: "red" }}>*</span>
                </label>
                <DatePicker
                  selected={formData.eventDate}
                  onChange={(date) =>
                    setFormData({ ...formData, eventDate: date })
                  }
                  dateFormat="dd/MM/yyyy"
                  className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 text-xs ${
                    formData.eventDate !== "" ? "bg-white" : "bg-gray-100"
                  } border-gray-300`}
                  placeholderText="Event Date"
                  wrapperClassName="w-full"
                  minDate={new Date()}
                />
                {check && formData.eventDate === "" && (
                  <p className="text-red-600 text-xs">Date Required</p>
                )}
                <label
                  htmlFor="location"
                  className="mt-2 block text-black font-['Manrope'] font-semibold text-xs pt-3"
                >
                  Event Location City <span style={{ color: "red" }}>*</span>
                </label>
                <div className="relative">
                  <input
                    id="location"
                    name="location"
                    type="text"
                    onChange={handleVenueChange}
                    value={formData.selectedLocations}
                    className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 text-xs ${
                      formData.selectedLocations !== ""
                        ? "bg-white"
                        : "bg-gray-100"
                    } border-gray-300`}
                    placeholder="Event Location City"
                  />
                  {suggestions.length > 0 && (
                    <ul className="w-full absolute left-0 right-0 bg-white border border-gray-300 rounded mt-1 max-h-40 overflow-y-auto z-10">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          className="px-3 py-2 cursor-pointer hover:bg-gray-200"
                          onClick={() => handleVenueSuggestions(suggestion)}
                        >
                          {suggestion}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {check && formData.selectedLocations === "" && (
                  <p className="text-red-600 text-xs">
                    Event Location City Required
                  </p>
                )}
                <div className="flex items-center mt-4">
                  <input
                    id="sendOnWhatsapp"
                    name="sendOnWhatsapp"
                    type="checkbox"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        sendOnWhatsapp: e.target.checked,
                      });
                    }}
                    checked={formData.sendOnWhatsapp}
                    className="w-3 h-3 border rounded border-black focus:ring-0 focus:border-pink-500 text-xs"
                  />
                  <label
                    htmlFor="sendOnWhatsapp"
                    className="ml-2 text-gray-700 text-xs "
                  >
                    Send me updates on{" "}
                    <span className="text-green-500">Whatsapp</span>
                  </label>
                </div>
                {check && formData.sendOnWhatsapp === false && (
                  <p className="text-red-600 text-xs">
                    By filling out the following, you agree to receive updates
                    via WhatsApp.
                  </p>
                )}
              </div>
            </div>
          )}
          {currentPage === 1 && (
            <div className="p-4 mb-4">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <input
                    id="validateOTP"
                    name="validateOTP"
                    type="text"
                    maxLength={6}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        validateOTP: e.target.value,
                      });
                    }}
                    className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                      formData.validateOTP !== "" ? "bg-white" : "bg-gray-100"
                    } border-gray-300`}
                    placeholder="Enter the OTP"
                  />
                </div>
              </div>
            </div>
          )}
          {currentPage === 2 && (
            <div className="p-4 mb-4">
              <div className="grid grid-cols-12 gap-4">
                {formDetails.ocassions.map((ocassion, index) => (
                  <div
                    key={index}
                    className={`col-span-6 rounded-lg bg-white drop-shadow-xl flex justify-center text-center items-center p-4 cursor-pointer font-['Manrope'] text-black font-semibold ${
                      formData.selectedOcassions.includes(ocassion)
                        ? "text-white"
                        : ""
                    }`}
                    style={{
                      backgroundColor: formData.selectedOcassions.includes(
                        ocassion
                      )
                        ? "#F7919E"
                        : "#FFFFFF",
                    }}
                    onClick={() => {
                      setFormData((prevData) => {
                        const isSelected =
                          prevData.selectedOcassions.includes(ocassion);
                        return {
                          ...prevData,
                          selectedOcassions: isSelected
                            ? prevData.selectedOcassions.filter(
                                (item) => item !== ocassion
                              )
                            : [...prevData.selectedOcassions, ocassion],
                        };
                      });
                    }}
                  >
                    {ocassion}
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentPage === 3 && (
            <div className="p-4 mb-4">
              <div className="grid grid-cols-12 gap-4">
                {formDetails.services.map((service, index) => (
                  <div
                    key={index}
                    className={`col-span-6 rounded-lg bg-white drop-shadow-xl flex text-center justify-center items-center p-4 cursor-pointer font-['Manrope'] text-black font-semibold ${
                      formData.selectedServices.includes(service)
                        ? "text-white"
                        : ""
                    }`}
                    style={{
                      backgroundColor: formData.selectedServices.includes(
                        service
                      )
                        ? "#F7919E"
                        : "#FFFFFF",
                    }}
                    onClick={() => {
                      setFormData((prevData) => {
                        const isSelected =
                          prevData.selectedServices.includes(service);
                        return {
                          ...prevData,
                          selectedServices: isSelected
                            ? prevData.selectedServices.filter(
                                (item) => item !== service
                              )
                            : [...prevData.selectedServices, service],
                        };
                      });
                    }}
                  >
                    {service}
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentPage === 4 && (
            <div className="p-4 mb-4">
              <div className="grid grid-cols-12 gap-4">
                {budget.map((budgetRangeObj, index) => (
                  <div
                    key={index}
                    className={`col-span-12 rounded-lg bg-white drop-shadow-xl flex text-center justify-center items-center p-4 cursor-pointer font-['Manrope'] text-black font-semibold ${
                      formData.selectedBudgetRanges.length > 0 &&
                      formData.selectedBudgetRanges[0].range ===
                        budgetRangeObj.budget
                        ? "text-white"
                        : ""
                    }`}
                    style={{
                      backgroundColor:
                        formData.selectedBudgetRanges.length > 0 &&
                        formData.selectedBudgetRanges[0].range ===
                          budgetRangeObj.budget
                          ? "#F7919E"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      setFormData((prevData) => ({
                        ...prevData,
                        selectedBudgetRanges: [
                          {
                            id: budgetRangeObj.id,
                            range: budgetRangeObj.budget,
                          },
                        ],
                      }));
                    }}
                  >
                    {decodeURIComponent(budgetRangeObj.budget)}
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentPage === 5 && (
            <div className="p-4 mb-4">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <input
                    id="guestrange"
                    name="guestrange"
                    type="text"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        selectedGuestRange: e.target.value,
                      });
                    }}
                    value={formData.selectedGuestRange}
                    className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                      formData.selectedGuestRange !== ""
                        ? "bg-white"
                        : "bg-gray-100"
                    } border-gray-300`}
                    placeholder="Insert Exact Number of Guests"
                  />
                </div>
                {formDetails.guestRanges.map((guestRange, index) => (
                  <div
                    key={index}
                    className={`col-span-6 rounded-lg bg-white drop-shadow-xl flex text-center justify-center items-center p-4 cursor-pointer font-['Manrope'] text-black font-semibold ${
                      formData.selectedGuestRange === guestRange
                        ? "text-white"
                        : ""
                    }`}
                    style={{
                      backgroundColor:
                        formData.selectedGuestRange === guestRange
                          ? "#F7919E"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        selectedGuestRange: guestRange,
                      });
                    }}
                  >
                    {guestRange + "+"}
                  </div>
                ))}
              </div>
            </div>
          )}
          {currentPage === 6 && (
            <div className="p-4 mb-4">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                  <input
                    id="locations"
                    name="locations"
                    type="text"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        eventVenue: e.target.value,
                      });
                    }}
                    value={formData.eventVenue}
                    className={`w-full px-3 py-2 mt-1 border rounded focus:border-pink-500 ${
                      formData.eventVenue !== "" ? "bg-white" : "bg-gray-100"
                    } border-gray-300`}
                    placeholder="Enter your Venue"
                  />
                </div>
                {/* {formDetails.locations.map((guestRange, index) => (
                  <div
                    key={index}
                    className={`col-span-6 rounded-lg bg-white drop-shadow-xl flex text-center justify-center items-center p-4 cursor-pointer font-['Manrope'] text-black font-semibold ${
                      formData.selectedLocations === guestRange
                        ? "text-white"
                        : ""
                    }`}
                    style={{
                      backgroundColor:
                        formData.selectedLocations === guestRange
                          ? "#F7919E"
                          : "#FFFFFF",
                    }}
                    onClick={() => {
                      setFormData({
                        ...formData,
                        selectedLocations: guestRange,
                      });
                    }}
                  >
                    {guestRange}
                  </div>
                ))} */}
              </div>
            </div>
          )}
          {currentPage === 7 && (
            <div className="p-4 mb-4 mt-[-10px] h-[300px]">
              <img
                src={thankyou}
                alt="thanks"
                className="w-full h-[400px] object-cover"
              />
            </div>
          )}
        </div>
        <div
          className="w-full mt-[530px] md:mt-[590px] text-white flex justify-center fixed inset-x-0"
          style={{
            marginBottom: "-60px",
          }}
        >
          <div className="w-full flex md:w-[350px] justify-center">
            <PrimaryButton
              text={currentPage < 7 ? "Next" : "Done"}
              handleClick={() => {
                if (currentPage === 0) {
                  setCheck(true);
                  if (
                    formData.fullName === "" ||
                    formData.phnNumber.length > 10 ||
                    formData.phnNumber.length < 10 ||
                    formData.phnNumber === "" ||
                    formData.eventDate === "" ||
                    formData.selectedLocations === "" ||
                    formData.sendOnWhatsapp === false
                  ) {
                    return;
                  }
                  sendOtpRequest(formData);
                } else if (currentPage === 1) {
                  validateOtpRequest(formData);
                } else if (currentPage === 3) {
                  if (
                    formData.selectedServices.length > 0 &&
                    currentServiceBudgetPage <= formData.selectedServices.length
                  ) {
                    // Add current selection to form data
                    const selectedBudget = formData.selectedBudgetRanges.find(
                      (range) =>
                        range.id ===
                        servicesData.find(
                          (item) => item.service === selectedServiceName
                        ).id
                    );

                    if (selectedBudget) {
                      setFormData((prevData) => ({
                        ...prevData,
                        selectedBudgets: [
                          ...prevData.selectedBudgets,
                          selectedBudget,
                        ],
                        selectedBudgetRanges: [],
                      }));
                    }

                    const result = servicesData.find(
                      (item) => item.service === formData.selectedServices[0]
                    );
                    const decodedStrings = result.budgets.map((str) => str);

                    setSelectedServiceName(formData.selectedServices[0]);
                    setBudget(decodedStrings);

                    let currPage = currentPage;
                    currPage++;
                    setCurrentPage(currPage);
                    setCurrentServiceBudgetPage(0);
                  }

                  if (formData.selectedServices.length === 0) {
                    let currPage = currentPage;
                    currPage++;
                    currPage++;
                    setCurrentPage(currPage);
                  }
                } else if (currentPage === 4) {
                  if (
                    formData.selectedServices.length > 0 &&
                    currentServiceBudgetPage <
                      formData.selectedServices.length - 1
                  ) {
                    // Add current selection to form data
                    const selectedBudget = formData.selectedBudgetRanges[0]; // Get the single selected budget

                    if (selectedBudget) {
                      setFormData((prevData) => ({
                        ...prevData,
                        selectedBudgets: [
                          ...(prevData.selectedBudgets || []), // Ensure it's an array
                          selectedBudget,
                        ],
                        selectedBudgetRanges: [], // Clear the selection for the next step
                      }));
                    }

                    const result = servicesData.find(
                      (item) =>
                        item.service ===
                        formData.selectedServices[currentServiceBudgetPage + 1]
                    );
                    const decodedStrings = result.budgets.map((str) => str);

                    setSelectedServiceName(
                      formData.selectedServices[currentServiceBudgetPage + 1]
                    );
                    setCurrentServiceBudgetPage((prev) => prev + 1);
                    setBudget(decodedStrings);
                  } else {
                    const selectedBudget = formData.selectedBudgetRanges[0]; // Get the single selected budget

                    if (selectedBudget) {
                      setFormData((prevData) => ({
                        ...prevData,
                        selectedBudgets: [
                          ...(prevData.selectedBudgets || []), // Ensure it's an array
                          selectedBudget,
                        ],
                        selectedBudgetRanges: [], // Clear the selection for the next step
                      }));
                    }
                    let currPage = currentPage;
                    currPage++;
                    setCurrentPage(currPage);
                  }
                } else if (currentPage === 6) {
                  updateGenerateLead(formData);
                  let currPage = currentPage;
                  currPage++;
                  setCurrentPage(currPage);
                } else if (currentPage < 7) {
                  let currPage = currentPage;
                  currPage++;
                  setCurrentPage(currPage);
                } else {
                  setShowModal(false);
                  setCurrentPage(0);
                  setCurrentServiceBudgetPage(0);
                  setFormData({
                    fullName: "",
                    phnNumber: "",
                    eventDate: "",
                    eventVenue: "",
                    sendOnWhatsapp: true,
                    selectedOcassions: [],
                    selectedServices: [],
                    selectedBudgetRanges: [],
                    selectedGuestRange: "",
                    selectedLocations: "",
                  });
                }
              }}
              styles={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: "600",
                width: "92%",
                maxWidth: "576px ",
                height: "50px",
              }}
            />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default GetInTouchModal;
